import React from 'react'
import styled from 'styled-components'
import Top from '../../assets/img/accounts/arrow-top-default.svg'
import TopHover from '../../assets/img/accounts/arrow-top-hover.svg'
import TopSelected from '../../assets/img/accounts/arrow-top-selected.svg'
import Favourite from '../../assets/img/accounts/favourite-default.svg'
import FavouriteHover from '../../assets/img/accounts/favourite-hover.svg'
import FavouriteSelected from '../../assets/img/accounts/favourite-selected.svg'
import IconButton from '../UIComponents/IconButton'

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 80px;
`

const ActionIcons = ({ onClickDefaultLoginAccount, onToggleFavourite, isFavourite, isDefaultLoginAccount }) => {
  return (
    <ActionsContainer>
      <IconButton
        defaultIcon={Top}
        hoverIcon={TopHover}
        selectedIcon={TopSelected}
        onClick={onClickDefaultLoginAccount}
        selected={isDefaultLoginAccount}
        isDisabled={!isDefaultLoginAccount}
      />
      <IconButton defaultIcon={Favourite} hoverIcon={FavouriteHover} selectedIcon={FavouriteSelected} onClick={onToggleFavourite} selected={isFavourite} />
    </ActionsContainer>
  )
}

export default ActionIcons
