import { useSelector } from 'react-redux'
import { useGetAccountsByUserIdQuery, useGetOrganizationsQuery } from '../../features/api/accountsApi'
import { useGetConfigByUserIdQuery, useGetAccountsConfigByAccountsIdsQuery } from '../../features/api/accountsConfigApi'

/**
  Custom hook to fetch accounts data, configuration data, and accounts configuration data for a user.
**/

const useAccountsData = () => {
  const userId = useSelector(state => state.accounts.user.data.id)

  const { data: organizationsData, error: organizationsError, isLoading: areOrganizationLoading } = useGetOrganizationsQuery()

  const { data: accountsData, error: accountsError, isLoading: isAccountsLoading } = useGetAccountsByUserIdQuery(userId, {
    skip: !userId
  })

  const { data: configData, error: configError, isLoading: isConfigLoading } = useGetConfigByUserIdQuery(userId, {
    skip: !userId
  })

  const accountsIds = accountsData ? accountsData.map(acc => acc.id) : []

  const { data: accountConfigData, error: accountConfigError, isLoading: isAccountConfigLoading } = useGetAccountsConfigByAccountsIdsQuery(accountsIds, {
    skip: accountsIds.length === 0
  })

  return {
    userId,
    organizationsData,
    areOrganizationLoading,
    organizationsError,
    accountsData,
    accountsError,
    isAccountsLoading,
    configData,
    configError,
    isConfigLoading,
    accountConfigData,
    accountConfigError,
    isAccountConfigLoading
  }
}

export default useAccountsData
