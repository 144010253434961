/* createApi: React-specific entry point that automatically generates hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../accounts/accountsSlice'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (() => {
      const url = new URL(window.location.origin)
      url.port = '8001'
      return `${url.origin}/v1/`
    })() // temporal access api
    // `${window.location.origin}:8001/v1/`
  }),
  endpoints: build => ({
    getUserByUsername: build.query({
      query: username => ({
        url: `user/?filter={"name": ${username}}`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data[0] })) // returns array of 1, but there is only one
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error: error.toString() }))
        }
      }
    }),
    getAccountsByUserId: build.query({
      query: userId => ({
        url: `user/${userId}/accounts`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userAccounts' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userAccounts', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userAccounts', error: error.toString() }))
        }
      }
    }),
    getOrganizations: build.query({
      query: () => ({
        url: `organization`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'organizations' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'organizations', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'organizations', error: error.toString() }))
        }
      }
    }),
    // not used at the moment
    getOrganizationByOrgId: build.query({
      query: orgId => ({
        url: `organization/${orgId}`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'organizations' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'organizations', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'organizations', error: error.toString() }))
        }
      }
    })
  })
})

// Export hooks based on the defined endpoints
export const { useGetUserByUsernameQuery, useGetAccountsByUserIdQuery, useGetOrganizationsQuery, useGetOrganizationByOrgIdQuery } = accountsApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
