import React from 'react'
import AccountsList from './AccountsList'
import styled from 'styled-components'

const LayoutWrapper = styled.div`
  margin-top: 0px;
  margin-left: 60px;
  margin-right: 60px;
  padding: 2px;
`

const ErrorMessage = styled.div`
  color: white;
  font-size: 18px;
  margin: 20px 0;
`

const LoadingMessage = styled.div`
  font-size: 18px;
  margin: 20px 0;
  color: white;
`

const AccountsLayout = ({ t, isLoading, error }) => {
  if (isLoading) {
    return (
      <LayoutWrapper>
        <LoadingMessage>Loading...</LoadingMessage>
      </LayoutWrapper>
    )
  }

  if (error) {
    return (
      <LayoutWrapper>
        <ErrorMessage>Error loading data</ErrorMessage>
      </LayoutWrapper>
    )
  }

  return (
    <LayoutWrapper>
      <AccountsList t={t} />
    </LayoutWrapper>
  )
}
export default AccountsLayout
