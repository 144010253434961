import React, { useEffect, useState, useMemo } from 'react'
import styled, { injectGlobal } from 'styled-components'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import layersLogo from '../assets/img/layers-new.png'
import userStoredData from '../userStoredData'
import get from 'lodash/get'
import { FALLBACK_LANGUAGE, ROUTES } from '../constants'
import LanguageButtons from '../../src/components/UserComponents/LanguageButtons'

import { useDispatch, useSelector } from 'react-redux'
import { localeSet } from '../features/locale/localeSlice'
import { setMessagesFromLocale } from '@layers-frontend/commons/store/actions/messages'
import { LoginForm } from './LayersComponents/LoginForm'
import { useGetUserByTokenQuery } from '../features/api/authApiSlice'
import { getMessagesTranslator } from '@layers-frontend/commons/store/selectors/messages'
import { Snackbar } from '@mui/material'

const mediaQueries = style => `@media (max-width: 768px) { ${style} }`

const LoginToAccounts = () => {
  const [showErrorNotification, setShowErrorNotification] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()

  const translator = useSelector(getMessagesTranslator)
  const t = useMemo(() => translator, [translator])
  const availableLocales = useSelector(state => state.config.i18n.availableLocales)
  const tokenData = useSelector(state => state.users.token)
  const token = tokenData?.data?.access_token

  const { user, error } = useGetUserByTokenQuery(token, {
    skip: !token
  })

  useEffect(() => {
    const storedLocale = userStoredData.locale
    const isStoredLocaleAvailable = availableLocales.some(available => storedLocale === available.locale)
    const locale = isStoredLocaleAvailable ? storedLocale : FALLBACK_LANGUAGE

    dispatch(localeSet(locale))
    dispatch(setMessagesFromLocale(locale))
  }, [availableLocales, dispatch])

  useEffect(() => {
    injectGlobal`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    .loginInput input:-webkit-autofill,
    .loginInput input:-webkit-autofill:hover, 
    .loginInput input:-webkit-autofill:focus,
    .loginInput textarea:-webkit-autofill,
    .loginInput textarea:-webkit-autofill:hover,
    .loginInput textarea:-webkit-autofill:focus,
    .loginInput select:-webkit-autofill,
    .loginInput select:-webkit-autofill:hover,
    .loginInput select:-webkit-autofill:focus {
      border: 0px;
      -webkit-text-fill-color: white;
      color: white;
      -webkit-box-shadow: unset;
      box-shadow: unset;
      background-color: transparent;
      transition: background-color 5000s ease-in-out 0s;
    }
  @media (max-width: 768px) {
    .loginInput{
    font-size: 26px !important;
    width: 456px !important;
    height: 82px !important;
    }
  }
  `
  }, [])

  useEffect(() => {
    if (token) sessionStorage.setItem('access_token', token)
  }, [token])

  useEffect(() => {
    if (tokenData?.error?.error) {
      setShowErrorNotification(true)
    }
  }, [tokenData, t])

  const handleClose = () => {
    setShowErrorNotification(false)
  }

  const hasLoggedIn = !!user

  if (hasLoggedIn) {
    const previousePage = get(location, 'state.redirectedFrom')
    const redirectRoute = previousePage || `/${ROUTES.ACCOUNTS_ROUTE}`

    return <Navigate to={redirectRoute} replace />
  }

  if (error) {
    sessionStorage.removeItem('access_token', token)
  }

  return (
    <>
      <LanguageButtonsWrapper>
        <LanguageButtons showGlobeIcon inputStyle={{ marginLeft: '10px' }} />
      </LanguageButtonsWrapper>
      <LoginWrapper>
        <RadialGradientBG aria-hidden="true" />
        <LoginContent>
          <HemavBrandingWrapper>
            <LayersImg src={layersLogo} />
          </HemavBrandingWrapper>
          <LoginForm t={t} />
          <ContactWrapper>
            {/*             <ContactLabel>{t('Not a user yet ?')}</ContactLabel>
            <ContactLink onClick={contactHandler}>{capitalize(t('get a free trial'))}</ContactLink> */}
          </ContactWrapper>
          <Snackbar
            className="ERROR_NOTIFICATION_CLASS"
            open={showErrorNotification}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={handleClose}
            message={tokenData?.error?.meta.response.status === 400 ? t('Invalid credentials') : t('An error occurred from the server. Please try again')}
          />
        </LoginContent>
      </LoginWrapper>
      <Outlet />
    </>
  )
}

const LoginWrapper = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 5;
`

export const RadialGradientBG = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(119.22% 119.22% at 4.02% 8.7%, #4d2359 0%, #1c2541 36.46%, #1a3844 69.41%, #688245 100%);
`

const LoginContent = styled.div`
  margin: auto;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LanguageButtonsWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 50px;
  min-width: 200px;
  z-index: 6;
  ${mediaQueries('right: 20px; min-width: 130px; & > div, a { font-size: 24px; } & .fas { display: none; } ')}
`

const HemavBrandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  & > img {
    margin-bottom: 0;
  }
`

const LayersImg = styled.img`
  padding-bottom: 50px;
  width: 100px;
  ${mediaQueries('width: 200px')};
`

const ContactWrapper = styled.div`
  margin: auto 20px 20px;
  display: flex;
  flex-direction: row;
  width: 230px;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries('width: 240px; font-size: 16px;')}
`
export default LoginToAccounts
