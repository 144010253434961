import React from 'react'
import AccountActions from './AccountActions'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { ListItem, ListItemButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

const AccountItem = ({ account, name, organization, policy, onClickDefaultLoginAccount, onToggleFavourite, isFavourite, isDefaultLoginAccount }) => {
  const matches = useMediaQuery('(min-width:780px)')

  return (
    <ListItem
      className="accounts-list-item"
      secondaryAction={
        <AccountActions
          onClickDefaultLoginAccount={() => onClickDefaultLoginAccount(account.id)}
          onToggleFavourite={() => onToggleFavourite(account.id)}
          isFavourite={isFavourite}
          isDefaultLoginAccount={isDefaultLoginAccount}
        />
      }
      sx={{
        padding: 0
      }}
    >
      <ListItemButton
        sx={{
          bgcolor: 'rgba(18, 44, 61, 1)',
          borderRadius: 2,
          height: 48,
          paddingLeft: 2,
          paddingRight: 2,
          m: 1,
          color: `${themeColors.vomitColor}`,
          gridTemplateColumns: '2fr 1fr 1fr 80px',
          display: matches ? 'grid' : 'flex',
          flexDirection: 'column',
          flexFlow: 'column',
          ':hover': { border: '2px solid #17ecb2' }
        }}
        onClick={event => alert(name)}
      >
        <span>
          {/*    <AccountIcon type={policy && policy?.customer_type && policy?.customer_type?.value} /> */}
          {name}
        </span>
        <span>{organization}</span>
        {/*  <span>{policy && policy.default_season_label && policy.default_season_label?.value}</span> */}
        {/*  <span>{policy && policy.customer_type && policy.customer_type?.value && findLabelforType(policy?.customer_type?.value)}</span> */}
      </ListItemButton>
    </ListItem>
  )
}

export default AccountItem
