import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import FilterModuleContainer from '../../containers/DashboardContainers/FilterModuleContainer'
import DashboardViewerTogglerContainer from '../../containers/TopBar/DashboardViewerTogglerContainer'
import SearchViewContainer from '../../containers/SearchContainers/SearchViewContainer'
import ProfileContainer from '../../containers/UserContainers/ProfileContainer'
import LayersLogo from '../../assets/img/layersCurvaT.png'
import { DisappearingTitle, Title } from '../UIComponents/titles'
import { useSelector } from 'react-redux'

const TopBarWrapper = styled.div`
  position: sticky;
  display: flex;
  left: 0px;
  right: 0;
  top: 0;
  z-index: 2;
  height: 60px;
  background-color: ${UIStyles.blueColorOpacity};
  border-bottom: 1px solid ${UIStyles.lightGrey};
  padding-right: 90px;
`

const Logo = styled.img`
  width: auto;
  height: 60px;
`

const RightMenu = styled.div`
  margin-right: 0;
  margin-left: auto;
`

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ currentLocation, user }) => {
  const accountsUser = useSelector(state => state.accounts.user.data)
  return (
    <TopBarWrapper>
      <Logo src={LayersLogo} />
      {(currentLocation.isDashboard || currentLocation.isViewer) && <SearchViewContainer />}
      {currentLocation.isDashboard && <FilterModuleContainer />}
      {currentLocation.isViewer && null}
      {currentLocation.isAccounts && (
        <Title>
          <DisappearingTitle text="Welcome back " timeout={5000} /> &nbsp;{`${accountsUser && accountsUser.name !== null ? accountsUser.name : ''}`}
        </Title>
      )}

      <RightMenu>
        {!currentLocation.isAccounts && (
          <DashboardViewerTogglerContainer isLayers={currentLocation.isViewer} isDashboard={currentLocation.isDashboard} isBiLabs={currentLocation.isBiLabs} />
        )}
        <ProfileContainer />
      </RightMenu>
    </TopBarWrapper>
  )
}
