/* createApi: React-specific entry point that automatically generates hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../accounts/configSlice'

export const accountsConfigApi = createApi({
  reducerPath: 'accountsConfigApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (() => {
      const url = new URL(window.location.origin)
      url.port = '8002'
      return `${url.origin}/v1/`
    })() // temporal access api
    // `${window.location.origin}/config`
  }),
  tagTypes: ['Policies'],
  endpoints: build => ({
    getConfigByUserId: build.query({
      query: userId => ({
        url: `entity?filter={"id": "${userId}"}`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data[0] })) // returns array of 1, but there is only one user
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error: error.toString() }))
        }
      }
    }),
    getAccountsConfigByAccountsIds: build.query({
      query: accountIds => ({
        url: `entity?filter={"id": [${accountIds.map(id => `"${id}"`).join(',')}]}`,
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userAccounts', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userAccounts', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userAccounts', error }))
        }
      }
    }),
    putPolicies: build.mutation({
      query: ({ userId, policy }) => ({
        url: `entity/${userId}`,
        method: 'PUT',
        body: policy
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data })) // here it does NOT return array of 1 for one user so no need for data[0]
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error }))
        }
      }
    }),
    getPolicies: build.query({
      query: () => ({
        url: `parameter?filter={"entity_type":"user"}`,
        method: 'GET'
      }),
      providesTags: () => [{ type: 'Policies' }], // tags the result of this query for cache invalidation and re-fetching data
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'policies', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'policies', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'policies', error }))
        }
      },
      refetch: () => {},
      keepUnusedDataFor: 24 * 60 * 60 // Cache for 1 day
    })
  })
})

// Export hooks based on the defined endpoints
export const { useGetConfigByUserIdQuery, useGetAccountsConfigByAccountsIdsQuery, useGetPoliciesQuery, usePutPoliciesMutation } = accountsConfigApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
