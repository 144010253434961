import React, { useEffect } from 'react'
import { injectGlobal } from 'styled-components'
import Accounts from '../containers/AccountsContainer/Accounts'
import withTranslator from './HOCComponents/withTranslator'
import { useGetUserByUsernameQuery } from '../features/api/accountsApi'
import { useGetPoliciesQuery } from '../features/api/accountsConfigApi'
/* import { MenuItem, Select } from '@mui/material' */

function AccountPage({ t }) {
  const [username] = React.useState('Luis Pedro')

  /*   const handleChange = event => {
    setUserName(event.target.value)
  } */
  useEffect(() => {
    injectGlobal`
    body {background:#15384f}
    }
  `
  }, [])

  // const username = 'multiple_account_organization'
  // const username = 'multiple_account'
  // const username = 'single_account'

  /* const temportalUserSelect = (
    <Select labelId="demo-select-small-label" id="demo-select-small" value={username} label="Username" onChange={handleChange}>
      <MenuItem value={'multiple_account'}>multiple_account</MenuItem>
      <MenuItem value={'single_account'}>single_account</MenuItem>
      <MenuItem value={'Luis Pedro'}>Luis Pedro</MenuItem>
      <MenuItem value={'Técnico_Campo_CORTEVA_BRASIL'}>Técnico_Campo_CORTEVA_BRASIL</MenuItem>
      <MenuItem value={'Técnico_Campo_SYNGENTA_BRASIL'}>Técnico_Campo_SYNGENTA_BRASIL</MenuItem>
      <MenuItem value={'AGRICULTOR_BRASIL'}>AGRICULTOR_BRASIL</MenuItem>
      <MenuItem value={'multiple_account_organization'}>multiple_account_organization</MenuItem>
    </Select>
  ) */
  const { data: policiesData, error: policiesError, isLoading: policiesLoading } = useGetPoliciesQuery()
  const { data, error, isLoading } = useGetUserByUsernameQuery(`"${username}"`)

  if (isLoading || policiesLoading) return <div>Loading...</div>
  if (error || policiesError) return <div>Error: {error}</div>

  return (
    <div>
      {/*     {temportalUserSelect} */}
      {data && policiesData && <Accounts t={t} />}
    </div>
  )
}

export default withTranslator(AccountPage)
