import { createSlice } from '@reduxjs/toolkit'

/**
 * @typedef {Object} TokenData
 * @property {string} access_token - The access token string.
 * @property {string} token_type - The type of the token, usually "bearer".
 * @property {string} expiration_time - The expiration time of the token, e.g., "1000 minutes".
 */

const initialState = {
  token: { key: 'token', data: null, status: 'idle', error: null }, // data type:  TokenData,
  user: { key: 'user', data: null, status: 'idle', error: null }
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchDataStart(state, action) {
      const { key } = action.payload
      state[key].error = null
      state[key].status = 'loading'
    },
    fetchDataSuccess(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'succeeded'
      state[key].data = payload
      state[key].error = null
    },
    fetchDataFailure(state, action) {
      const { key, error } = action.payload
      state[key].status = 'failed'
      state[key].error = error
    }
  }
})

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, updateUserPolicies } = usersSlice.actions

export default usersSlice.reducer
