import React, { useEffect } from 'react'
import AccountsLayout from '../../components/AccountComponents/AccountsLayout'
import useAccountsData from './useAccountsData'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserAccountsPolicies } from '../../features/accounts/configSlice'

const updatePolicies = (accountConfig, policies) => {
  const policyLookup = policies.reduce((acc, param) => {
    acc[param.id] = param
    return acc
  }, {})

  return accountConfig.map(account => {
    const modifiedPolicy = Object.keys(account.policy).reduce((acc, policyId) => {
      if (policyLookup[policyId]) {
        acc[policyLookup[policyId].name] = {
          ...policyLookup[policyId],
          value: account.policy[policyId]
        }
      }
      return acc
    }, {})
    return {
      ...account,
      modifiedPolicy
    }
  })
}

const Accounts = ({ t }) => {
  const policyParameters = useSelector(state => state.accountsConfig.policies.data)
  const {
    accountsError,
    isAccountsLoading,
    configData,
    configError,
    isConfigLoading,
    accountConfigData,
    accountConfigError,
    isAccountConfigLoading,
    areOrganizationLoading,
    organizationsError
  } = useAccountsData()

  const dispatch = useDispatch()

  useEffect(() => {
    if (configData && accountConfigData && policyParameters) {
      const accounts = updatePolicies(accountConfigData, policyParameters)
      dispatch(updateUserAccountsPolicies({ key: 'userAccounts', payload: accounts }))
    }
  }, [policyParameters, accountConfigData, dispatch, configData])

  useEffect(() => {
    if (accountConfigData && configData && policyParameters) {
      const userConfig = updatePolicies(configData, policyParameters)
      dispatch(updateUserAccountsPolicies({ key: 'user', payload: userConfig }))
    }
  }, [policyParameters, configData, accountConfigData, dispatch])

  return (
    <AccountsLayout
      t={t}
      isLoading={isAccountsLoading || isConfigLoading || isAccountConfigLoading || areOrganizationLoading}
      error={accountsError || configError || accountConfigError || organizationsError}
    />
  )
}
export default Accounts
